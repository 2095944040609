<template>
    <div class="edit-profile-detail">
        <div class="title" v-html="$translate(type.toUpperCase())" />
        <div class="section">
            <div class="f-14 m-b-16">해당 정보는 매칭 참고용으로 매니저에게만 공유됩니다.</div>
            <div class="column">
                <div
                    class="item"
                    :class="{ selected: idx === selectedIdx }"
                    v-for="(item, idx) in healthStatus"
                    :key="item.id"
                    v-html="item.name"
                    @click="onClickItem(item, idx)"
                />
            </div>
        </div>
        <div class="section" v-if="selectedIdx === 1">
            <TextareaWithX :placeholder="placeholder" v-model="healthDetail" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Health',
    // props: ['type', '',
    props: {
        type: String,
        stage: Number,
    },
    data: () => ({
        selectedIdx: null,
        healthStatus: [
            {
                id: 0,
                name: '양호',
            },
            {
                id: 1,
                name: '좋지않음',
            },
        ],
        healthDetail: null,
    }),
    computed: {
        profile() {
            return this.$store.getters.profile || []
        },
        placeholder() {
            return '현재 병원진료를 받고 있다거나 유전적인 질병이 있으시다면 최대한 상세히 적어주세요. 매니저에게만 공개됩니다.'
        },
        disabled() {
            return !(this.selectedIdx === 0 || (this.selectedIdx === 1 && this.healthDetail))
        },
    },
    watch: {
        healthDetail: function () {
            if (this.healthDetail) this.$emit('disabled', this.disabled)
        },
        selectedIdx() {
            this.$emit('disabled', this.disabled)
        },
        stage() {
            this.init()
            this.$emit('disabled', this.disabled)
        },
    },
    methods: {
        onClickItem(item, idx) {
            this.selectedIdx = idx
            this.$emit('disabled', this.disabled)

            if (idx === 0) {
                this.healthDetail = null
                this.$set(this.profile, 'health_detail', '')
            }
        },
        onSave(key) {
            if (this.selectedIdx === 1) {
                this.$set(this.profile, 'health_detail', this.healthDetail)
            }
            this.$set(this.profile, 'health_status', this.selectedIdx)
        },
        init() {
            this.selectedIdx = this.profile.health_status
            this.healthDetail = this.profile.health_detail
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style scoped lang="scss">
.section {
    ::v-deep .textarea-with-x {
        height: 250px !important;

        textarea::placeholder {
            line-height: 24px;
        }
    }
}
</style>
